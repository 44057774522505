<template>
    <div class="flex flex-col justify-center items-center h-auto mt-16 lg:mt-32">
        <h1 class="uppercase text-lg">
            {{$t('language.choose')}}
        </h1>
        <div class="mt-10 flex flex-col items-center">
            <button class="mb-10 rounded-full w-40 bgImage font-bold transform hover:scale-110
                duration-300"
                @click="switchLocale('en')"
                >
                <div class="bg-black bg-opacity-50 w-40 p-4 rounded-full text-white
                    font-extrabold uppercase">
                    English
                </div>
            </button>
            <button class="rounded-full w-40 bgImageFlag font-bold transform hover:scale-110
                duration-300"
                @click="switchLocale('it')">
                <div class="bg-black bg-opacity-50 w-40 p-4 rounded-full text-white
                    font-extrabold uppercase">
                    Italiano
                </div>
            </button>
        </div>
    </div>
</template>

<script>

import { useStore } from 'vuex';

export default {

  setup() {
    const store = useStore();

    const changeLocale = (locale) => store.dispatch('changeLocale', locale);

    return {
      changeLocale,
    };
  },

  methods: {
    switchLocale(locale) {
      this.$i18n.locale = locale;
      this.changeLocale(locale);
      this.$router.push('/harp');
    },
  },

};
</script>

<style scoped>
    .bgImage {
        background-image: url('../images/englishFlag.png');
        background-size: 100%;
    }
    .bgImageFlag {
        background-image: url('../images/italianFlag.png');
        background-size: 100%;
    }
</style>
